<template>
  <div class="h-w-100">
    <b-navbar toggleable="lg" type="dark" variant="grey">
      <b-navbar-brand :to="{ name: 'MyShop' }"
        ><img src="/img/logov2_white.png" class="header-logo" alt="Logo Flixby"
      /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            exact-active-class="active"
            v-if="userInfo.shop.type === 'flixby'"
            :to="{ name: 'MyShop/Products' }"
          >
            <b-icon-list-nested class="mr-1"></b-icon-list-nested> {{ $t("seller.menu.products") }}
          </b-nav-item>
          <b-nav-item exact-active-class="active" :to="{ name: 'MyShop/Lives' }">
            <b-icon-camera-video class="mr-1"></b-icon-camera-video> {{ $t("seller.menu.lives") }}
          </b-nav-item>
          <b-nav-item
            exact-active-class="active"
            v-if="userInfo.shop.type === 'flixby'"
            :to="{ name: 'MyShop/Orders' }"
          >
            <b-icon-cart-check class="mr-1"></b-icon-cart-check> {{ $t("seller.menu.orders") }}
          </b-nav-item>
          <b-nav-item exact-active-class="active" :to="{ name: 'MyShop/Configuration' }" v-if="isSellerAdmin">
            <b-icon-gear class="mr-1"></b-icon-gear> {{ $t("seller.menu.configuration") }}
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <locale-switcher />

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em v-html="userInfo.name"></em>
            </template>
            <b-dropdown-item href="/logout">{{ $t("common.logout") }}</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item v-b-modal.modal-help>
            <b-icon-question-circle />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container id="seller-container" class="p-0" fluid>
      <router-view />
    </b-container>
    <b-modal id="modal-help" @shown="initPLyr" :hide-footer="true" size="xl">
      <div ref="help" data-plyr-provider="youtube" data-plyr-embed-id="urGVV34AcYc"></div>
    </b-modal>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { mapGetters } from "vuex";
import Plyr from "plyr";

export default {
  name: "Seller",
  components: { LocaleSwitcher },
  computed: {
    ...mapGetters("authentication", ["userInfo", "isSellerAdmin"]),
  },
  methods: {
    initPLyr() {
      new Plyr(this.$refs.help, {
        autoplay: true,
        controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "fullscreen"],
      });
    },
  },
  mounted() {
    if (!localStorage.getItem("help-shown")) {
      localStorage.setItem("help-shown", "1");

      this.$bvModal.show("modal-help");
    }
  },
};
</script>

<style scoped>
#seller-container {
  height: calc(100% - 56px);
}
</style>
