<template>
  <div class="mt-5">
    <orders :list-api="listOrder"></orders>
  </div>
</template>

<script>
import api from "@/utils/api";
import Orders from "@/components/Orders";

export default {
  name: "MyShopOrders",
  components: { Orders },
  methods: {
    listOrder(page, limit, filters) {
      return api.listOrders(page, limit, filters);
    },
  },
};
</script>

<style scoped></style>
