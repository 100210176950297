<template>
  <div class="mt-5">
    <div class="text-center my-2" v-if="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-container v-else>
      <b-row>
        <b-col>
          <h1>{{ $t("seller.lives.show.title", { date }) }}</h1>
        </b-col>
        <b-col class="text-right">
          <b-button v-if="webShareApiSupported" class="mr-2" @click="webShareApiShare">
            <b-icon-share-fill></b-icon-share-fill>
          </b-button>
          <template v-else>
            <b-button
              class="mr-2"
              target="_blank"
              :href="`https://facebook.com/sharer/sharer.php?u=${encodedShareUrl}`"
            >
              <b-icon-facebook></b-icon-facebook>
            </b-button>
            <b-button class="mr-2" @click="copyLinkToClipboard">
              <b-icon-link></b-icon-link>
            </b-button>
          </template>
          <b-button class="mr-2" @click="copyIframeToClipboard">
            <b-icon-code-slash></b-icon-code-slash>
          </b-button>
          <b-button variant="primary" @click="startLive">
            <b-icon-play-fill />
            {{ $t(live.startTime ? "seller.lives.show.current-live-btn" : "seller.lives.show.start-btn") }}
          </b-button>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-tabs pills card @activate-tab="activateTab">
          <b-tab :title="$t('seller.lives.show.tabs.products')" :active="!live.startTime">
            <b-card-text>
              <b-overlay :show="shopifyProductDataLoading">
                <ul ref="products" class="uk-list uk-list-striped" style="margin: -1.25rem -1.25rem -1.8rem;">
                  <li
                    v-for="(liveProduct, i) in live.orderedProducts"
                    :key="i"
                    class="product mb-2"
                    :data-id="liveProduct.id"
                  >
                    <b-row align-v="center">
                      <b-col cols="auto"><b-icon-justify class="product-handle"></b-icon-justify></b-col>
                      <b-col cols="auto">
                        <b-form-input
                          class="product-order"
                          v-model.number="liveProduct.order"
                          type="number"
                          step="1"
                          min="1"
                          size="sm"
                          @change="productsOrderChanged"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="auto">
                        <b-img :src="getProductImage(liveProduct)" class="product-image" />
                      </b-col>
                      <b-col>
                        {{ getProductName(liveProduct) }}
                      </b-col>
                      <b-col cols="auto">
                        <label>
                          <b-form-checkbox
                            class="d-inline"
                            v-model="liveProduct.presaleAllowed"
                            switch
                            size="sm"
                            @change="() => productsPresaleChanged(liveProduct)"
                          ></b-form-checkbox>
                          <small>{{ $t("seller.lives.show.product.presale") }}</small>
                        </label>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-overlay>
            </b-card-text>
          </b-tab>
          <b-tab v-if="isFlixby" :title="$t('seller.lives.show.tabs.orders')" :active="!!live.startTime">
            <b-card-text>
              <div class="text-center">
                <orders :list-api="listOrder" :live-id="live.id"></orders>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab :title="$t('seller.lives.show.tabs.viewers')" :disabled="!live.startTime" lazy>
            <b-card-text>
              <div class="text-center">
                <b-icon-eye-fill font-scale="5"></b-icon-eye-fill>
                <p>{{ $t("seller.lives.show.viewers-count", { viewersCount }) }}</p>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab v-if="isFlixby" :title="$t('seller.lives.show.tabs.dashboard')" lazy>
            <b-card-text>
              <div class="text-center">
                <dashboard :live-id="live.id"></dashboard>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab v-if="isShopifyEmbed" :title="$t('seller.lives.show.tabs.embedded-script')" lazy>
            <b-card-text>
              <b-jumbotron>
                <p>
                  {{ embeddedScriptButton }}
                </p>
                <p>
                  {{ embeddedScript }}
                </p>
                <div class="text-right">
                  <b-button variant="primary" @click="copyEmbeddedScript">{{
                    $t("seller.lives.show.copy.embedded-script")
                  }}</b-button>
                </div>
              </b-jumbotron>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import api from "@/utils/api";
import helper from "@/utils/helper";
import Orders from "@/components/Orders";
import copy from "copy-text-to-clipboard";
import Bugsnag from "@bugsnag/js";
import Sortable from "sortablejs";
import Dashboard from "@/components/Dashboard";
import { mapGetters } from "vuex";

export default {
  name: "Live",
  components: { Orders, Dashboard },
  data() {
    return {
      live: {},
      isLoading: false,
      viewersCount: 0,
      sortable: null,
      shopifyProductDataLoading: false,
      shopifyProductData: {},
    };
  },
  computed: {
    ...mapGetters("authentication", ["userInfo"]),
    date() {
      return helper.formatDateTime(this.live.startTime || this.live.expectedStartTime, this.$t("seller.lives.show.at"));
    },
    shareUrl() {
      return helper.buildShareUrl(this.live, this.$i18n.locale);
    },
    encodedShareUrl() {
      return encodeURIComponent(this.shareUrl);
    },
    webShareApiSupported() {
      const uA = navigator.userAgent;
      const vendor = navigator.vendor;
      const isSafariDesktop = /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);

      return typeof navigator.share === "function" && !isSafariDesktop;
    },
    productsContainer() {
      return this.$refs.products;
    },
    isFlixby() {
      return this.userInfo.shop.type === "flixby";
    },
    apiUrl() {
      return process.env.VUE_APP_BACKEND_URL;
    },
    embedUrl() {
      return process.env.VUE_APP_EMBED_URL;
    },
    isShopifyEmbed() {
      return this.userInfo.shop.type === "shopify_embed";
    },
    embeddedScriptButton() {
      return (
        "<!-- Place the trigger button where you want -->" +
        `<div class="livestuff-trigger" livestuff-live-id="${
          this.live.id
        }" style="display: flex; flex-direction: column; align-items: center; cursor: pointer;">
            <img src="${this.apiUrl + '/' +
              (this.live.logoUrl ?? this.live.shop.logoUrl)}" style="border-radius: 9px; margin-bottom: 10px;" height="150" width="200" alt="Live cover" />
            <p>${this.$t("seller.lives.show.embedded-script.button", {
              date: new Date(this.live.expectedStartTime).toLocaleString(),
            })}</p>
        </div>` +
        "<!---->"
      );
    },
    embeddedScript() {
      return (
        "<!-- Add this to the end of your file -->" +
        "<script" +
        `>(function(){var c=document.getElementsByTagName("head")[0],d=document.getElementsByTagName("body")[0];if(!c||!d){console.error("HTML file is incorrect");return}var a=document.createElement("script");a.defer="defer",a.src="${this.embedUrl}";c.append(a);var e=document.createElement("div");e.id="livestuff",d.append(e)})()</` +
        "script>" +
        "<!---->"
      );
    },
  },
  created() {
    this.isLoading = true;
    api
      .getLive(this.$route.params.id)
      .then(response => {
        this.live = response.data;

        if (this.isShopifyEmbed) {
          this.loadShopifyData();
        }
      })
      .catch(e => {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      })
      .then(() => (this.isLoading = false));

    api
      .getLiveViewersCount(this.$route.params.id)
      .then(response => (this.viewersCount = response.data.count))
      .catch(e => {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      });
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.products) {
        this.initProductSortable();
      }
    }, 1000);
  },
  methods: {
    getProductImage(liveProduct) {
      if (this.isShopifyEmbed) {
        return this.shopifyProductData[liveProduct.shopifyProductUrl]?.image || null;
      }

      return liveProduct.product.photoUrl;
    },
    getProductName(liveProduct) {
      if (this.isShopifyEmbed) {
        return this.shopifyProductData[liveProduct.shopifyProductUrl]?.name || liveProduct.shopifyProductUrl;
      }

      return liveProduct.product.name;
    },
    loadShopifyData() {
      this.shopifyProductDataLoading = true;
      const promises = [];
      this.live.orderedProducts.forEach(product => {
        const url = product.shopifyProductUrl;

        if (this.shopifyProductData[url]) {
          return;
        }

        promises.push(
          api
            .getShopifyProductData(url)
            .then(response => {
              this.shopifyProductData[url] = response.data;
            })
            .catch(e => this.handleError(e))
        );
      });

      Promise.all(promises).then(() => (this.shopifyProductDataLoading = false));
    },
    initProductSortable() {
      if (this.sortable) {
        return;
      }

      this.sortable = Sortable.create(this.$refs.products, {
        handle: ".product-handle",
        onUpdate: evt => {
          for (let index = 0, length = evt.to.children.length; index < length; index++) {
            const element = evt.to.children[index];
            const product = this.live.orderedProducts.find(
              liveProduct => liveProduct.id === parseInt(element.dataset.id)
            );
            product.order = index + 1;
          }

          this.updateProductsOrder();
        },
      });
    },
    productsPresaleChanged(product) {
      api.updateLiveProduct(this.live.id, product.id, { presaleAllowed: product.presaleAllowed }).catch(e => {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      });
    },
    productsOrderChanged() {
      const newOrderedProducts = this.live.orderedProducts.map(liveProduct => liveProduct.id);
      newOrderedProducts.sort(
        (a, b) =>
          this.live.orderedProducts.find(liveProduct => liveProduct.id === a).order -
          this.live.orderedProducts.find(liveProduct => liveProduct.id === b).order
      );

      this.initProductSortable();
      this.sortable.sort(newOrderedProducts, true);

      this.updateProductsOrder();
    },
    updateProductsOrder() {
      const productsOrder = {};
      this.live.orderedProducts.forEach(liveProduct => (productsOrder[liveProduct.id] = liveProduct.order));

      api.updateLiveProductOrder(this.live.id, productsOrder).catch(e => {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      });
    },
    startLive() {
      this.$router.push({ name: "UserStream", params: { id: this.live.id } });
    },
    listOrder(page, limit, filters) {
      return api.listLiveOrders(this.live.id, page, limit, filters);
    },
    webShareApiShare() {
      navigator
        .share({
          title: this.$t("share.title", { shopName: this.live.shop.name }),
          text: this.$t("share.description", { liveDate: helper.formatDateTime(this.live.expectedStartTime) }),
          url: this.shareUrl,
        })
        .catch(() => {});
    },
    copyLinkToClipboard() {
      if (copy(this.shareUrl)) {
        this.$bvToast.toast(this.$t("share.copy.confirmation.text"), {
          title: this.$t("share.copy.confirmation.title"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    copyIframeToClipboard() {
      const url = location.origin + this.$router.resolve({ name: "UserStream", params: { id: this.live.id } }).href;
      const iframe = `<iframe id="flixby-embed-${this.live.id}"
          title="${this.live.title}"
          width="320"
          height="580"
          src="${url}">
      </iframe>`;

      if (copy(iframe)) {
        this.$bvToast.toast(this.$t("share.copy.confirmation.text"), {
          title: this.$t("share.copy.confirmation.title"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    copyEmbeddedScript() {
      if (copy(this.embeddedScriptButton + this.embeddedScript)) {
        this.$bvToast.toast(this.$t("share.copy.confirmation.text"), {
          title: this.$t("share.copy.confirmation.title"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    activateTab(newTabIndex) {
      if (newTabIndex === 0) {
        this.initProductSortable();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.product-image {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.product-order {
  width: 60px;
}
</style>
