<template>
  <div class="mt-5">
    <b-container class="text-center">
      <h1>{{ $t("seller.myshop.shopify.title") }}</h1>
      <div class="mt-5">
        <b-icon icon="circle-fill" animation="throb" font-scale="4"></b-icon>
      </div>
    </b-container>
  </div>
</template>

<script>
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Shopify",
  created() {
    if (this.$route.query.code && this.$route.query.shop && this.$route.query.hmac && this.$route.query.state) {
      api
        .sellerShopifyConnect(window.location.href.split("?")[1])
        .then(() => {
          this.$bvToast.toast(this.$t("seller.myshop.shopify.success"), {
            title: "Shopify",
            variant: "success",
            solid: true,
          });

          setTimeout(() => this.$router.push({ name: "MyShop/Configuration" }), 1000);
        })
        .catch(e => this.handleError(e));
    }
  },
  methods: {
    handleError(e) {
      this.$bvToast.toast(this.$t("common.error.text"), {
        title: this.$t("common.error.title"),
        variant: "danger",
        solid: true,
      });

      console.error(e);
      if (process.env.NODE_ENV === "production") {
        Bugsnag.leaveBreadcrumb(JSON.stringify(e));
        Bugsnag.notify(e);
      }
    },
  },
};
</script>

<style scoped></style>
