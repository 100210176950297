<template>
  <div class="mt-5">
    <b-container>
      <b-row>
        <b-col>
          <h1>{{ $t("seller.lives.title") }}</h1>
        </b-col>
        <b-col class="text-right">
          <b-form-input
            type="search"
            v-model="filter"
            debounce="250"
            :placeholder="$t('common.search')"
            class="d-inline w-auto mr-3"
          ></b-form-input>
          <b-button variant="primary" @click="addLive"> <b-icon-plus /> {{ $t("seller.lives.add-btn") }} </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="lives-table"
        responsive
        :items="lives"
        :fields="columns"
        :per-page="limit"
        :filter="filter"
        :current-page="currentPage"
        :busy="isLoading"
        :tbody-tr-class="rowClass"
      >
        <template #cell(actions)="row">
          <b-button @click="copyLinkToClipboard(row.item)" size="sm" variant="danger" class="mr-1">
            {{ $t("seller.lives.actions.copy-link") }}
          </b-button>
          <b-button
            :href="`https://facebook.com/sharer/sharer.php?u=${encodedShareUrl(row.item)}`"
            size="sm"
            variant="primary"
            class="mr-1"
          >
            {{ $t("seller.lives.actions.share-on-facebook") }}
          </b-button>
          <b-button @click="startLive(row.item.id)" size="sm" variant="success" class="mr-4">
            {{ $t("seller.lives.actions.moderate") }}
          </b-button>

          <b-button size="sm" :to="{ name: 'MyShop/Live', params: { id: row.item.id } }" class="mr-1">
            <b-icon-eye></b-icon-eye>
          </b-button>
          <b-button size="sm" @click="editLive(row.item, row.index)" variant="primary" class="mr-1">
            <b-icon-pencil></b-icon-pencil>
          </b-button>
          <b-button size="sm" variant="danger" @click="deleteLive(row.item, row.index)">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col xl="1" offset-xl="7" offset-lg="5" md="2" offset-md="4" sm="2" cols="3">
          <b-form-select id="per-page-select" v-model="limit" :options="[5, 10, 20, 50, 100]" size="sm"></b-form-select>
        </b-col>
        <b-col xl="4" lg="5" md="6" sm="10" cols="9">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="limit"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="live-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="handleOk"
      :title="liveEdited && liveEdited.id ? liveEdited.name : $t('seller.lives.new.title')"
      size="xl"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <live-form ref="live-form" :live="liveEdited" v-on:live-submitted="liveSubmitted"></live-form>
    </b-modal>
  </div>
</template>

<script>
import api from "@/utils/api";
import LiveForm from "@/components/live/LiveForm";
import _clone from "lodash.clonedeep";
import helper from "@/utils/helper";
import Bugsnag from "@bugsnag/js";
import copy from "copy-text-to-clipboard";

export default {
  name: "Lives",
  components: { LiveForm },
  data() {
    return {
      liveEdited: null,
      limit: 5,
      filter: null,
      currentPage: 1,
      total: 0,
      isLoading: false,
      columns: [
        {
          key: "title",
          label: this.$t("seller.lives.columns.title"),
          tdClass: "align-middle",
        },
        {
          key: "expectedStartTime",
          label: this.$t("seller.lives.columns.expected-start-time"),
          formatter: value => helper.formatDate(value) + " " + helper.formatTime(value),
          tdClass: "align-middle",
        },
        {
          key: "actions",
          label: this.$t("seller.products.columns.actions"),
          tdClass: 'text-right'
        },
      ],
    };
  },
  methods: {
    getNewLive() {
      return {
        title: null,
        expectedStartTime: null,
        products: [],
        configuration: {},
      };
    },
    encodedShareUrl(live) {
      return encodeURIComponent(this.shareUrl(live));
    },
    shareUrl(live) {
      return helper.buildShareUrl(live, this.$i18n.locale);
    },
    copyLinkToClipboard(live) {
      if (copy(this.shareUrl(live))) {
        this.$bvToast.toast(this.$t("share.copy.confirmation.text"), {
          title: this.$t("share.copy.confirmation.title"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    startLive(liveId) {
      this.$router.push({ name: "UserStream", params: { id: liveId } });
    },
    resetModal() {
      this.liveEdited = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.$refs["live-form"].handleSubmit();
    },
    lives({ currentPage, perPage, filter, sortBy, sortDesc }) {
      this.isLoading = true;
      return api
        .listLives(currentPage, perPage || 10, {
          filters: typeof filter === "object" ? filter : { search: filter },
          orderBy: { column: sortBy, order: sortDesc ? "DESC" : "ASC" },
        })
        .then(response => {
          this.isLoading = false;

          const payload = response.data;
          this.total = payload.total;
          return payload.data;
        })
        .catch(e => this.handleError(e));
    },
    addLive() {
      this.liveEdited = this.getNewLive();
      this.$bvModal.show("live-modal");
    },
    editLive(live) {
      this.liveEdited = _clone(live);
      this.$bvModal.show("live-modal");
    },
    deleteLive(live) {
      this.$bvModal
        .msgBoxConfirm(this.$t("seller.lives.delete.confirm"), { cancelTitle: this.$t("common.cancel-btn") })
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          return api.deleteLive(live).then(() => {
            this.$bvToast.toast(this.$t("seller.lives.delete.success"), {
              title: this.$t("common.success.title"),
              variant: "success",
              solid: true,
            });

            this.refreshTable();
          });
        })
        .catch(e => this.handleError(e));
    },
    refreshTable() {
      this.$refs["lives-table"].refresh();
    },
    liveSubmitted() {
      this.refreshTable();
      this.$bvModal.hide("live-modal");
    },
    handleError(e) {
      this.$bvToast.toast(this.$t("common.error.text"), {
        title: this.$t("common.error.title"),
        variant: "danger",
        solid: true,
      });

      console.error(e);
      if (process.env.NODE_ENV === "production") {
        Bugsnag.leaveBreadcrumb(JSON.stringify(e));
        Bugsnag.notify(e);
      }
    },
    rowClass(item, type) {
      if (item && type === "row") {
        if (helper.getDate(item.expectedStartTime) < new Date()) {
          return "outdated";
        }
      }

      return null;
    },
  },
};
</script>

<style scoped></style>
