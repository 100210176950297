<template>
  <div class="mt-5">
    <b-container class="text-center">
      <h1>{{ $t("seller.myshop.prestashop.title") }}</h1>
      <div class="mt-5">
        <b-icon icon="circle-fill" animation="throb" font-scale="4"></b-icon>
      </div>
    </b-container>
  </div>
</template>

<script>
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Prestashop",
  created() {
    if (this.$route.query.access_token && this.$route.query.return_url) {
      api
        .sellerPrestashopConnect(window.location.href.split("?")[1])
        .then(response => {
          this.$bvToast.toast(this.$t("seller.myshop.prestashop.success"), {
            title: "Prestashop",
            variant: "success",
            solid: true,
          });

          let returnUrl = this.$route.query.return_url;
          if (returnUrl.indexOf("?") === -1) {
            returnUrl += "?";
          } else {
            returnUrl += "&";
          }

          returnUrl += `access_token=${encodeURIComponent(response.data.accessToken)}`;

          setTimeout(() => (document.location = returnUrl), 1000);
        })
        .catch(e => this.handleError(e));
    }
  },
  methods: {
    handleError(e) {
      this.$bvToast.toast(this.$t("common.error.text"), {
        title: this.$t("common.error.title"),
        variant: "danger",
        solid: true,
      });

      console.error(e);
      if (process.env.NODE_ENV === "production") {
        Bugsnag.leaveBreadcrumb(JSON.stringify(e));
        Bugsnag.notify(e);
      }
    },
  },
};
</script>

<style scoped></style>
