<template>
  <div class="mt-5">
    <b-container>
      <h1 class="text-center">{{ $t("seller.myshop.title") }} {{ userInfo.shop.name }}</h1>
      <dashboard></dashboard>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dashboard from "@/components/Dashboard";

export default {
  name: "MyShop",
  components: {
    Dashboard,
  },
  computed: {
    ...mapGetters("authentication", ["userInfo"]),
  },
  mounted() {
    if (this.userInfo.shop.type !== "flixby") {
      this.$router.push({ name: "MyShop/Lives" });
    }
  },
};
</script>

<style scoped></style>
