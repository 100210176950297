<template>
  <div class="mt-5">
    <b-container>
      <b-row>
        <b-col cols="2">
          <h1>{{ $t("seller.products.title") }}</h1>
        </b-col>
        <b-col class="text-right">
          <b-form-select
            id="order-filter-live"
            class="d-inline w-auto mr-3"
            v-model="filter.category"
            :options="categoriesOptions"
          ></b-form-select>
          <b-form-select
            id="order-filter-live"
            class="d-inline w-auto mr-3"
            v-model="filter.live"
            :options="livesOptions"
          ></b-form-select>
          <b-form-input
            type="search"
            v-model="filter.search"
            debounce="250"
            :placeholder="$t('common.search')"
            class="d-inline w-auto mr-3"
          ></b-form-input>
          <b-button
            v-if="
              configuration.shopifyAccessToken || (configuration.prestashop && configuration.prestashop.accessToken)
            "
            variant="primary"
            @click="refreshProducts"
            :disabled="isLoading"
          >
            <b-spinner small type="grow" v-if="isLoading" class="align-middle mr-2"></b-spinner>
            <b-icon-arrow-repeat class="align-middle mr-2" v-else /> {{ $t("seller.products.refresh-btn") }}
          </b-button>
          <b-button v-else variant="primary" @click="addProduct">
            <b-icon-plus /> {{ $t("seller.products.add-btn") }}
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="products-table"
        responsive
        :items="products"
        :fields="columns"
        :per-page="limit"
        :filter="filter"
        :current-page="currentPage"
        :busy="isLoading"
      >
        <template #cell(images)="row">
          <b-img v-if="row.value.length > 0" :src="uncache(row.value[0])" width="50" fluid />
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" variant="primary" @click="editProduct(row.item, row.index)" class="mr-1">
            <b-icon-pencil></b-icon-pencil>
          </b-button>
          <b-button size="sm" variant="danger" @click="deleteProduct(row.item, row.index)">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col cols="1" offset="7">
          <b-form-select id="per-page-select" v-model="limit" :options="[5, 10, 20, 50, 100]" size="sm"></b-form-select>
        </b-col>
        <b-col cols="4">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="limit"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="product-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="handleOk"
      :title="productEdited && productEdited.id ? productEdited.name : $t('seller.products.new.title')"
      :ok-title="$t('common.save-btn')"
      :cancel-title="$t('common.cancel-btn')"
      no-close-on-esc
      no-close-on-backdrop
    >
      <product-form
        ref="product-form"
        :product="productEdited"
        v-on:product-submitted="productSubmitted"
      ></product-form>
    </b-modal>
  </div>
</template>

<script>
import api from "@/utils/api";
import ProductForm from "@/components/product/ProductForm";
import _clone from "lodash.clonedeep";
import getSymbolFromCurrency from "currency-symbol-map";
import Bugsnag from "@bugsnag/js";
import helper from "@/utils/helper";

export default {
  name: "Products",
  components: { ProductForm },
  data() {
    return {
      productEdited: null,
      configuration: {
        shopifyAccessToken: false,
      },
      limit: 10,
      filter: {
        search: null,
        live: null,
        category: null,
      },
      livesOptions: [],
      categoriesOptions: [],
      currentPage: 1,
      total: 0,
      isLoading: false,
      columns: [
        {
          key: "images",
          label: this.$t("seller.products.columns.photo"),
          thStyle: "width: 50px;",
        },
        {
          key: "name",
          label: this.$t("seller.products.columns.name"),
          sortable: true,
          class: "align-middle",
        },
        {
          key: "actions",
          label: this.$t("seller.products.columns.actions"),
          thStyle: "width: 100px;",
        },
      ],
    };
  },
  methods: {
    randomNumber() {
      return Math.random();
    },
    uncache(image) {
      return image.indexOf("http") === 0 ? `${image}?rand=${this.randomNumber()}` : image;
    },
    getNewProduct() {
      return {
        name: null,
        description: null,
        price: {
          amount: null,
          currency: "EUR",
        },
        stock: null,
        allowPreOrder: false,
        images: [],
        attributes: {},
        variants: [],
      };
    },
    refreshConfiguration() {
      return api
        .getShopConfiguration()
        .then(response => (this.configuration = { ...this.configuration, ...response.data }))
        .catch(e => this.handleError(e));
    },
    resetModal() {
      this.productEdited = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.$refs["product-form"].handleSubmit();
    },
    products({ currentPage, perPage, filter, sortBy, sortDesc }) {
      this.isLoading = true;
      return api
        .listProducts(currentPage, perPage || 10, {
          filters: typeof filter === "object" ? filter : { search: filter },
          orderBy: { column: sortBy, order: sortDesc ? "DESC" : "ASC" },
        })
        .then(response => {
          this.isLoading = false;

          const payload = response.data;
          this.total = payload.total;
          return payload.data;
        });
    },
    addProduct() {
      this.productEdited = this.getNewProduct();
      this.$bvModal.show("product-modal");
    },
    editProduct(product) {
      this.productEdited = _clone(product);
      this.$bvModal.show("product-modal");
    },
    deleteProduct(product) {
      this.$bvModal
        .msgBoxConfirm(this.$t("seller.products.delete.confirm"), { cancelTitle: this.$t("common.cancel-btn") })
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          return api.deleteProduct(product).then(() => {
            this.$bvToast.toast(this.$t("seller.products.delete.success"), {
              title: this.$t("common.success.title"),
              variant: "success",
              solid: true,
            });

            this.refreshTable();
          });
        })
        .catch(e => {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        });
    },
    refreshProducts() {
      this.isLoading = true;
      return api.refreshProducts().then(() => {
        this.isLoading = false;
        this.refreshCategories();

        this.$nextTick(() => this.refreshTable());
      });
    },
    refreshTable() {
      this.$refs["products-table"].refresh();
    },
    productSubmitted() {
      this.refreshTable();
      this.$bvModal.hide("product-modal");
    },
    getSymbolFromCurrency(currency) {
      return getSymbolFromCurrency(currency);
    },
    refreshCategories() {
      api
        .listCategories(1, 1000, { light: true })
        .then(response => {
          const payload = response.data;
          this.categoriesOptions = payload.data.map(category => ({
            value: category.id,
            text: category.name,
          }));

          this.categoriesOptions.unshift({ value: null, text: "Catégories" });
        })
        .catch(e => this.handleError(e));
    },
  },
  created() {
    this.refreshConfiguration();

    api
      .listLives(1, 1000, { light: true })
      .then(response => {
        const payload = response.data;
        this.livesOptions = payload.data.map(live => ({
          value: live.id,
          text: helper.formatDate(live.expectedStartTime),
        }));

        this.livesOptions.unshift(
          { value: null, text: "Live" },
          { value: "none", text: this.$t("seller.products.no-live") }
        );
      })
      .catch(e => this.handleError(e));

    this.refreshCategories();
  },
};
</script>

<style scoped></style>
