<template>
  <div>
    <div class="text-center my-2" v-if="isLiveLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <form v-else ref="liveForm" @submit.stop.prevent="handleSubmit" class="no-padding-with-margin">
      <b-col class="my-2" cols="6">
        <b-form-group v-if="!live.type" :label="$t('seller.lives.new.type.title')">
          <b-form-radio type="radio" v-model="live.type" value="live">{{ $t('seller.lives.new.type.live') }}</b-form-radio>
          <b-form-radio type="radio" v-model="live.type" value="video">{{ $t('seller.lives.new.type.video') }}</b-form-radio>
        </b-form-group>
      </b-col>
      <b-tabs v-if="!!live.type" pills card v-model="currentTab">
        <b-tab v-if="live.type === 'video'" :title="$t('seller.lives.new.tab.live')">
          <b-form-group v-if="!live.id" :label="$t('seller.lives.new.form.youtube-video-url')" label-for="live-youtube-video-url-input">
            <b-input-group>
              <b-form-input v-model="live.youtubeVideoUrl" />
            </b-input-group>
          </b-form-group>
          <b-form-group v-else :label="$t('seller.lives.new.form.title')" label-for="live-title-input">
            <b-input-group>
              <b-form-input v-model="live.title" />
            </b-input-group>
          </b-form-group>
        </b-tab>
        <b-tab v-if="live.type === 'live'" :title="$t('seller.lives.new.tab.live')">
          <b-form-group :label="$t('seller.lives.new.form.title')" label-for="live-title-input">
            <b-input-group>
              <b-form-input v-model="live.title" />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('seller.lives.new.form.expected-start-time')"
            label-for="live-date-input"
            :invalid-feedback="$t('seller.lives.new.validation.expected-start-time')"
            :state="(date !== null && time !== null) || !showDateMissingError"
          >
            <b-input-group>
              <b-form-datepicker v-model="date"></b-form-datepicker>
              <b-form-timepicker v-model="time" @input="timeChanged"></b-form-timepicker>
            </b-input-group>
          </b-form-group>

          <image-input
            id="live-logo-input"
            :label="$t('seller.lives.new.form.logo')"
            :image="logo"
            @imageUpdate="handleImageUpdate"
          ></image-input>
        </b-tab>
        <b-tab :title="$t('seller.lives.new.tab.configuration')">
          <b-row>
            <b-col cols="6" v-if="isFlixby">
              <b-form-group :label="$t('seller.lives.new.form.stream-to.flixby')" label-for="live-private-input">
                <b-form-checkbox id="live-private-input" :checked="!live.private" @input="newValue => (live.private = !newValue)" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="facebookStreamAvailable && live.type === 'live'">
              <b-form-group
                :label="$t('seller.lives.new.form.stream-to.facebook')"
                label-for="live-stream-to-facebook-input"
              >
                <b-form-checkbox
                  id="live-stream-to-facebook-input"
                  v-model="live.streamToFacebook"
                  switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="isFlixby">
            <b-col cols="6">
              <b-form-checkbox
                v-model="live.configuration.shippingCostDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-shipping-cost") }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('seller.configuration.share-discount')"
                label-for="configuration-share-discount-input"
                v-if="!live.configuration.shareDiscountDisabled"
              >
                <b-input-group append="%">
                  <b-form-input
                    id="configuration-share-discount-input"
                    v-model="live.configuration.shareDiscount"
                    type="number"
                    number
                    min="0"
                    step="1"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-checkbox
                v-model="live.configuration.shareDiscountDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-share-discount") }}
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row v-if="isFlixby">
            <b-col sm="6">
              <b-form-group
                :label="$t('seller.configuration.replay-limit')"
                label-for="configuration-replay-limit-input"
                v-if="!live.configuration.replayLimitDisabled"
              >
                <b-form-input
                  id="configuration-replay-limit-input"
                  v-model="live.configuration.replayLimit"
                  type="number"
                  number
                  min="0"
                  step="1"
                />
              </b-form-group>
              <b-form-checkbox
                v-model="live.configuration.replayLimitDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-replay-limit") }}
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-checkbox
                v-if="isFlixby"
                v-model="live.configuration.shopPickUpDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-shop-pick-up") }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="live.configuration.showProductStockDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-show-product-stock") }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="live.type === 'live'" sm="6">
              <b-form-checkbox
                v-model="live.configuration.showProductBeforeStartDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-show-product-before-start") }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="live.configuration.showProductBeforeSelectedDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-show-product-before-selected") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="$t('seller.lives.new.tab.products')">
          <template v-if="isFlixby">
            <b-row class="mb-3" align-h="end" align-v="end">
              <b-col cols="auto">
                <b-form-select
                  id="order-filter-live"
                  class="d-inline w-auto mr-3"
                  v-model="filter.category"
                  :options="categoriesOptions"
                ></b-form-select>
              </b-col>
              <b-col cols="auto">
                <b-form-select
                  id="order-filter-live"
                  class="mr-3"
                  v-model="filter.live"
                  :options="livesOptions"
                ></b-form-select>
              </b-col>
              <b-col cols="auto">
                <b-form-input
                  type="search"
                  v-model="filter.search"
                  debounce="250"
                  :placeholder="$t('common.search')"
                  class="d-inline w-auto mr-3"
                ></b-form-input>
              </b-col>
              <b-col cols="auto">
                <b-form-checkbox
                  v-model="selectAllVisibleProductsCheckbox"
                  :value="true"
                  :unchecked-value="false"
                  @change="toggleSelectedProducts"
                  switch
                >
                  {{ $t("orders.actions.select-all") }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-table
              ref="products-table"
              responsive
              :items="products"
              :fields="columns"
              :per-page="limit"
              :filter="filter"
              :current-page="currentPage"
              :busy="isLoading"
            >
              <template #cell(images)="row">
                <b-img v-if="row.value.length > 0" :src="row.value[0]" width="50" fluid />
              </template>

              <template #cell(selection)="row">
                <b-form-checkbox
                  :checked="isSelected(row.item)"
                  @change="toggleSelection(row.item)"
                  switch
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col cols="3">
                <b-form-select
                  id="per-page-select"
                  v-model="limit"
                  :options="[5, 10, 20, 50, 100]"
                  size="sm"
                ></b-form-select>
              </b-col>
              <b-col cols="9">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="total"
                  :per-page="limit"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
          <template v-else-if="isShopifyEmbed">
            <b-input-group class="mb-3">
              <b-form-input
                v-model="shopifyProductUrl"
                placeholder="Product URL"
                @keydown.enter="addProductFromUrl"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="addProductFromUrl"><b-icon-plus></b-icon-plus></b-button>
              </b-input-group-append>
            </b-input-group>
            <b-overlay :show="shopifyProductDataLoading">
              <b-list-group>
                <b-list-group-item v-for="(product, index) in live.products" :key="product.url">
                  <b-row align-v="center">
                    <b-col cols="2">
                      <img
                        v-if="shopifyProductData[product.shopifyProductUrl]"
                        :src="shopifyProductData[product.shopifyProductUrl].image"
                        alt="Product image"
                        style="max-height: 50px;"
                      />
                    </b-col>
                    <b-col cols="8">{{ getShopifyProductName(product) }}</b-col>
                    <b-col cols="2" class="text-right">
                      <b-button variant="danger" @click="() => live.products.splice(index, 1)">
                        <b-icon-trash-fill></b-icon-trash-fill>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-overlay>
          </template>
        </b-tab>
      </b-tabs>
    </form>
    <footer class="modal-footer no-padding-with-margin">
      <button type="button" class="btn btn-secondary" @click="cancel">{{ $t("common.cancel-btn") }}</button>
      <button type="button" class="btn btn-primary" @click="handleSubmit">{{ saveButton }}</button>
    </footer>
  </div>
</template>

<script>
import api from "@/utils/api";
import helper from "@/utils/helper";
import _debounce from "lodash.debounce";
import Bugsnag from "@bugsnag/js";
import ImageInput from "@/components/ImageInput";
import { mapGetters } from "vuex";

export default {
  name: "LiveForm",
  components: { ImageInput },
  props: {
    live: Object,
  },
  data() {
    return {
      limit: 10,
      filter: {
        search: null,
        live: null,
        category: null,
      },
      livesOptions: [],
      categoriesOptions: [],
      currentPage: 1,
      total: 0,
      isLoading: false,
      time: null,
      columns: [
        {
          key: "images",
          label: this.$t("seller.products.columns.photo"),
          thStyle: "width: 50px;",
        },
        {
          key: "name",
          label: this.$t("seller.products.columns.name"),
          sortable: true,
          class: "align-middle",
        },
        {
          key: "selection",
          label: this.$t("seller.products.columns.actions"),
          thStyle: "width: 50px;",
        },
      ],
      currentTab: 0,
      seenTabs: [],
      showDateMissingError: false,
      selectAllVisibleProductsCheckbox: false,
      productsList: [],
      isLiveLoading: false,
      facebookStreamAvailable: false,
      shopifyProductUrl: "",
      shopifyProductDataLoading: false,
      shopifyProductData: {},
    };
  },
  computed: {
    ...mapGetters("authentication", ["userInfo"]),
    date: {
      get() {
        return this.live.expectedStartTime ? helper.formatDateISO(this.live.expectedStartTime) : null;
      },
      set(newValue) {
        this.setExpectedStartTime(
          newValue,
          this.live.expectedStartTime ? helper.formatTime(this.live.expectedStartTime, true) : null
        );
      },
    },
    apiUrl() {
      return process.env.VUE_APP_BACKEND_URL;
    },
    logo() {
      if (this.live.logoDataUri) {
        return this.live.logoDataUri;
      }

      if (this.live.logoUrl) {
        return `${this.apiUrl}/${this.live.logoUrl}`;
      }

      return null;
    },
    cannotSave() {
      return !this.live.id && (!this.seenTabs.includes(0) || !this.seenTabs.includes(1) || !this.seenTabs.includes(2));
    },
    saveButton() {
      return this.$t(this.cannotSave ? "seller.lives.new.form.next" : "common.save-btn");
    },
    isFlixby() {
      return this.userInfo.shop.type === "flixby";
    },
    isShopifyEmbed() {
      return this.userInfo.shop.type === "shopify_embed";
    },
  },
  created() {
    if (this.live.id) {
      this.isLiveLoading = true;
      api
        .getLive(this.live.id)
        .then(response => {
          this.live.products = response.data.products;

          if (this.isShopifyEmbed) {
            this.loadShopifyData();
          }
        })
        .catch(e => {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        })
        .then(() => (this.isLiveLoading = false));
    }

    this.time = this.live.expectedStartTime ? helper.formatTime(this.live.expectedStartTime, true) : null;

    api
      .getShopConfiguration()
      .then(response => {
        const defaultConfiguration = {
          shippingCostDisabled: response.data.shippingCostDisabled,
          shippingCost: response.data.shippingCost,
          shareDiscountDisabled: response.data.shareDiscountDisabled,
          shareDiscount: response.data.shareDiscount,
          replayLimitDisabled: response.data.replayLimitDisabled,
          replayLimit: response.data.replayLimit,
          showProductBeforeStartDisabled: response.data.showProductBeforeStartDisabled,
          showProductBeforeSelectedDisabled: response.data.showProductBeforeSelectedDisabled,
          shopPickUpDisabled: response.data.shopPickUpDisabled,
          showProductStockDisabled: response.data.showProductStockDisabled,
        };

        this.facebookStreamAvailable = !!response.data.facebookPage && !!response.data.facebookPage.access_token;

        if (!this.live.id) {
          this.live.streamToFacebook = this.facebookStreamAvailable;
        }

        this.live.configuration = { ...defaultConfiguration, ...this.live.configuration };
      })
      .catch(e => this.handleError(e));

    api
      .listLives(1, 1000, { light: true })
      .then(response => {
        const payload = response.data;
        this.livesOptions = payload.data.map(live => ({
          value: live.id,
          text: helper.formatDate(live.expectedStartTime),
        }));

        this.livesOptions.unshift(
          { value: null, text: "Live" },
          { value: "none", text: this.$t("seller.products.no-live") }
        );
      })
      .catch(e => this.handleError(e));

    this.refreshCategories();
  },
  methods: {
    refreshCategories() {
      api
        .listCategories(1, 1000, { light: true })
        .then(response => {
          const payload = response.data;
          this.categoriesOptions = payload.data.map(category => ({
            value: category.id,
            text: category.name,
          }));

          this.categoriesOptions.unshift({ value: null, text: "Catégories" });
        })
        .catch(e => this.handleError(e));
    },
    loadShopifyData() {
      this.shopifyProductDataLoading = true;
      const promises = [];
      this.live.products.forEach(product => {
        const url = product.shopifyProductUrl;

        if (this.shopifyProductData[url]) {
          return;
        }

        promises.push(
          api
            .getShopifyProductData(url)
            .then(response => {
              this.shopifyProductData[url] = response.data;
            })
            .catch(e => this.handleError(e))
        );
      });

      Promise.all(promises).then(() => (this.shopifyProductDataLoading = false));
    },
    addProductFromUrl() {
      const url = this.shopifyProductUrl.trim();
      if (url.length === 0) {
        return;
      }

      if (this.live.products.findIndex(product => product.shopifyProductUrl === url) !== -1) {
        return;
      }

      this.live.products.push({
        shopifyProductUrl: url,
        order: 0,
      });

      this.loadShopifyData();
      this.shopifyProductUrl = "";
    },
    getShopifyProductName(product) {
      return this.shopifyProductData[product.shopifyProductUrl]?.name || product.shopifyProductUrl;
    },
    getDefaultDate() {
      return helper.formatDateISO(new Date());
    },
    timeChanged: _debounce(function() {
      this.updateTime();
    }, 500),
    updateTime() {
      this.setExpectedStartTime(helper.formatDateISO(this.live.expectedStartTime), this.time);
    },
    setExpectedStartTime(date, time) {
      this.live.expectedStartTime = date + "T" + (time || "20:00:00") + helper.getTimezoneOffset(date);
    },
    checkFormValidity() {
      const valid = this.$refs.liveForm.checkValidity();

      if (!valid) {
        console.log("invalid");
      }

      return valid;
    },
    handleImageUpdate(base64Image) {
      this.live.logoDataUri = base64Image;
    },
    handleSubmit() {
      if (this.live.type === 'live' && (this.date === null || this.time === null)) {
        this.showDateMissingError = true;
        return;
      } else if (this.live.type === 'video') {
        this.live.expectedStartTime = this.getDefaultDate();
      }

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.cannotSave) {
        ++this.currentTab;
        return;
      }

      this.live.shopifyEmbeddedLive = this.isShopifyEmbed;

      let promise;
      if (this.live.id) {
        promise = api.updateLive(this.live);
      } else {
        promise = api.createLive(this.live);
      }

      return promise
        .then(() => {
          this.$emit("live-submitted");
        })
        .catch(e => this.handleError(e));
    },
    isSelected(product) {
      return this.live.products.some(liveProduct => liveProduct.product.id === product.id);
    },
    isAllVisibleProductsSelected() {
      return this.productsList.length === this.productsList.filter(product => this.isSelected(product)).length;
    },
    toggleSelection(product) {
      if (this.isSelected(product)) {
        this.unselectProduct(product);
      } else {
        this.selectProduct(product);
      }

      this.selectAllVisibleProductsCheckbox = this.isAllVisibleProductsSelected();
    },
    selectProduct(product) {
      const index = this.live.products.findIndex(liveProduct => liveProduct.product.id === product.id);
      if (index !== -1) {
        return;
      }

      this.live.products.push(this.getNewLiveProduct(product));
    },
    unselectProduct(product) {
      const index = this.live.products.findIndex(liveProduct => liveProduct.product.id === product.id);
      if (index === -1) {
        return;
      }

      this.live.products.splice(index, 1);
    },
    toggleSelectedProducts() {
      const isAllVisibleProductsSelected = this.isAllVisibleProductsSelected();

      this.productsList.forEach(product => {
        if (isAllVisibleProductsSelected) {
          this.unselectProduct(product);

          return;
        }

        this.selectProduct(product);
      });
    },
    getNewLiveProduct(product) {
      return {
        product: product,
        order: 0,
      };
    },
    products({ currentPage, perPage, filter, sortBy, sortDesc }) {
      this.isLoading = true;
      this.selectAllVisibleProductsCheckbox = false;
      return api
        .listProducts(currentPage, perPage || 10, {
          filters: typeof filter === "object" ? filter : { search: filter },
          orderBy: { column: sortBy, order: sortDesc ? "DESC" : "ASC" },
        })
        .then(response => {
          this.isLoading = false;

          const payload = response.data;
          this.total = payload.total;
          this.productsList = payload.data;

          this.selectAllVisibleProductsCheckbox = this.isAllVisibleProductsSelected();

          return this.productsList;
        })
        .catch(e => this.handleError(e));
    },
    priceInputValue(price) {
      return price ? price / 100 : 0;
    },
    setPrice(configurationKey, newPrice) {
      this.live.configuration[configurationKey] = newPrice ? parseInt((newPrice * 100).toFixed(0)) : 0;
    },
    handleError(e) {
      this.$bvToast.toast(this.$t("common.error.text"), {
        title: this.$t("common.error.title"),
        variant: "danger",
        solid: true,
      });

      console.error(e);
      if (process.env.NODE_ENV === "production") {
        Bugsnag.leaveBreadcrumb(JSON.stringify(e));
        Bugsnag.notify(e);
      }
    },
    cancel() {
      this.$bvModal.hide("live-modal");
    },
  },
  watch: {
    currentTab(newValue, oldValue) {
      [newValue, oldValue].forEach(value => {
        if (!this.seenTabs.includes(value)) {
          this.seenTabs.push(value);
        }
      });
    },
  },
};
</script>
