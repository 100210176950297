<template>
  <form v-if="product" ref="productForm" @submit.stop.prevent="handleSubmit">
    <image-input
      id="product-photo-input"
      :label="$t('seller.products.new.form.photo')"
      :image="photo(product)"
      @imageUpdate="base64Image => handleImageUpdate(product, base64Image)"
    ></image-input>

    <b-form-group
      :label="$t('seller.products.new.form.name')"
      label-for="product-name-input"
      :invalid-feedback="$t('seller.products.new.validation.name')"
      :state="product.name !== null ? product.name.length > 0 : null"
    >
      <b-form-input
        id="product-name-input"
        v-model="product.name"
        :state="product.name !== null ? product.name.length > 0 : null"
        required
      />
    </b-form-group>
    <b-form-group :label="$t('seller.products.new.form.description')" label-for="product-description-input">
      <b-form-textarea id="product-description-input" v-model="product.description" />
    </b-form-group>

    <b-row align-v="start" v-if="product.variants.length === 0">
      <b-col sm="4">
        <b-form-group
          :label="$t('seller.products.new.form.price')"
          label-for="product-price-input"
          :invalid-feedback="$t('seller.products.new.validation.price')"
          :state="product.price.amount !== null ? product.price.amount > 0 : null"
        >
          <b-input-group append="€">
            <b-form-input
              id="product-price-input"
              :value="getInputPrice(product)"
              @input="newPrice => setPrice(product, newPrice)"
              :state="product.price.amount !== null ? product.price.amount > 0 : null"
              type="number"
              number
              min="0"
              step="0.01"
              :required="product.variants.length === 0"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          :label="$t('seller.products.new.form.stock')"
          label-for="product-stock-input"
          :invalid-feedback="$t('seller.products.new.validation.stock')"
          :state="product.stock !== null ? product.stock !== '' : null"
        >
          <b-form-input
            id="product-stock-input"
            v-model="product.stock"
            :state="product.stock !== null ? product.stock !== '' : null"
            type="number"
            number
            min="0"
            step="1"
            :required="product.variants.length === 0"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group :label="$t('seller.products.new.form.weight')" label-for="product-weight-input">
          <b-input-group append="Kg">
            <b-form-input
              id="product-weight-input"
              v-model="product.attributes.weight"
              type="number"
              number
              min="0"
              step="0.001"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group :label="$t('seller.products.new.form.allow-pre-order')" label-for="product-allow-pre-order-input">
      <b-form-checkbox id="product-allow-pre-order-input" v-model="product.allowPreOrder" switch></b-form-checkbox>
    </b-form-group>

    <ul class="list-unstyled" v-if="product.variants.length > 0">
      <li v-for="(variant, index) in product.variants" :key="index">
        <h3>{{ $t("seller.products.new.variant.title") }} {{ index + 1 }}</h3>

        <image-input
          :id="`product-variant-${index}-photo-input`"
          :label="$t('seller.products.new.form.photo')"
          :image="photo(variant)"
          @imageUpdate="base64Image => handleImageUpdate(variant, base64Image)"
        ></image-input>

        <b-row align-v="start">
          <b-col sm="6">
            <b-form-group
              :label="$t('seller.products.new.form.price')"
              :label-for="`product-variant-${index}-price-input`"
              :invalid-feedback="$t('seller.products.new.validation.price')"
              :state="variant.price.amount !== null ? variant.price.amount > 0 : null"
            >
              <b-input-group append="€">
                <b-form-input
                  :id="`product-variant-${index}-price-input`"
                  :value="getInputPrice(variant)"
                  @input="newPrice => setPrice(variant, newPrice)"
                  :state="variant.price.amount !== null ? variant.price.amount > 0 : null"
                  type="number"
                  number
                  min="0"
                  step="0.01"
                  required
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('seller.products.new.form.stock')"
              :label-for="`product-variant-${index}-stock-input`"
              :invalid-feedback="$t('seller.products.new.validation.stock')"
              :state="variant.stock !== null ? variant.stock !== '' : null"
            >
              <b-form-input
                :id="`product-variant-${index}-stock-input`"
                v-model="variant.stock"
                :state="variant.stock !== null ? variant.stock !== '' : null"
                type="number"
                number
                min="0"
                step="1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-v="start">
          <b-col sm="6">
            <b-form-group
              :label="$t('seller.products.new.form.size')"
              :label-for="`product-variant-${index}-size-input`"
            >
              <b-form-input :id="`product-variant-${index}-size-input`" v-model="variant.attributes.size" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('seller.products.new.form.weight')"
              :label-for="`product-variant-${index}-weight-input`"
            >
              <b-input-group append="Kg">
                <b-form-input
                  :id="`product-variant-${index}-weight-input`"
                  v-model="variant.attributes.weight"
                  type="number"
                  number
                  min="0"
                  step="0.001"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="text-right">
            <b-button variant="danger" @click="deleteVariant(index)">
              <b-icon-trash></b-icon-trash> {{ $t("seller.products.delete-variant-btn") }}
            </b-button>
          </b-col>
        </b-row>
      </li>
    </ul>
    <b-button variant="outline-primary" @click="addVariant">
      <b-icon-plus /> {{ $t("seller.products.add-variant-btn") }}
    </b-button>
  </form>
</template>

<script>
import Vue from "vue";
import Compressor from "compressorjs";
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";
import ImageInput from "@/components/ImageInput";

export default {
  name: "ProductForm",
  components: { ImageInput },
  props: {
    product: Object,
  },
  methods: {
    photo(product) {
      if (product.photoDataUri) {
        return product.photoDataUri;
      }

      return product.photoUrl ? `${product.photoUrl}?rand=${this.randomNumber()}` : null;
    },
    handleImageUpdate(product, base64Image) {
      product.photoDataUri = base64Image;
    },
    randomNumber() {
      return Math.random();
    },
    uncache(image) {
      return image.indexOf("http") === 0 ? `${image}?rand=${this.randomNumber()}` : image;
    },
    getInputPrice(product) {
      return product.price.amount ? product.price.amount / 100 : null;
    },
    setPrice(product, newPrice) {
      product.price.amount = newPrice ? parseInt((newPrice * 100).toFixed(0)) : null;
    },
    getNewVariant() {
      return {
        price: {
          amount: null,
          currency: "EUR",
        },
        stock: null,
        images: [],
        attributes: {},
      };
    },
    addVariant() {
      this.product.variants.push(this.getNewVariant());
    },
    deleteVariant(index) {
      this.product.variants.splice(index, 1);
    },
    addPhoto(product, file) {
      if (!file) {
        return;
      }

      Vue.set(product, "loading", true);
      new Compressor(file, {
        quality: 0.6,
        checkOrientation: true,
        maxWidth: 1024,
        success: result => {
          this.blobToDataURI(result)
            .then(base64Result => {
              Vue.set(product.images, 0, base64Result);
              Vue.delete(product, "loading");
            })
            .catch(error => this.photoUploadError(product, error));
        },
        error: error => this.photoUploadError(product, error),
      });
    },
    blobToDataURI(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    photoUploadError(product, error) {
      this.$bvToast.toast(this.$t("common.error.text"), {
        title: this.$t("common.error.title"),
        variant: "danger",
        solid: true,
      });

      Vue.delete(product, "loading");
      console.log(error);
      if (process.env.NODE_ENV === "production") {
        Bugsnag.leaveBreadcrumb(JSON.stringify(error));
        Bugsnag.notify(error);
      }
    },
    checkFormValidity() {
      const valid = this.$refs.productForm.checkValidity();

      if (!valid) {
        if (this.product.name === null) {
          this.product.name = "";
        }

        if (this.product.variants.length === 0) {
          if (this.product.price.amount === null) {
            this.product.price.amount = "";
          }

          if (!this.product.price.currency) {
            this.product.price.currency = "EUR";
          }

          if (this.product.stock === null) {
            this.product.stock = "";
          }
        } else {
          this.product.variants.forEach(variant => {
            if (variant.price.amount === null) {
              variant.price.amount = "";
            }

            if (!variant.price.currency) {
              variant.price.currency = "EUR";
            }

            if (variant.stock === null) {
              variant.stock = "";
            }
          });
        }
      }

      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      let promise;
      if (this.product.id) {
        promise = api.updateProduct(this.product);
      } else {
        promise = api.createProduct(this.product);
      }

      return promise
        .then(() => {
          this.$emit("product-submitted");
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.message) {
            this.$bvToast.toast(error.response.data.message, {
              title: this.$t("common.error.title"),
              variant: "danger",
              solid: true,
            });

            return;
          }

          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        });
    },
  },
};
</script>
