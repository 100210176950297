<template>
  <div>
    <b-row class="mt-5">
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.todayCount }}</h2>
          <p>{{ $t("seller.myshop.todayCount") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.waitingPayment }}</h2>
          <p>{{ $t("seller.myshop.waitingPayment") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.waitingShipping }}</h2>
          <p>{{ $t("seller.myshop.waitingShipping") }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.cart }}</h2>
          <p>{{ $t("seller.myshop.cart") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.waiting }}</h2>
          <p>{{ $t("seller.myshop.waiting") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.orders }}</h2>
          <p>{{ $t("seller.myshop.orders") }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.onlineTotal }}</h2>
          <p>{{ $t("seller.myshop.onlineTotal") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.bankWireTotal }}</h2>
          <p>{{ $t("seller.myshop.bankWireTotal") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.payInShopTotal }}</h2>
          <p>{{ $t("seller.myshop.payInShopTotal") }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.avgCart }}</h2>
          <p>{{ $t("seller.myshop.avgCart") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.percentageSold }}</h2>
          <p>{{ $t("seller.myshop.percentageSold") }}</p>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2>{{ stats.ordersCount }}</h2>
          <p>{{ $t("seller.myshop.ordersCount") }}</p>
        </b-card>
      </b-col>
    </b-row>
    <center class="mx-auto inline-block mt-4">
      {{ $t('seller.myshop.downloadApp') }}
      <a :href="STORES.SHOP.ANDROID" target="_blank">
        <img width="180" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
      </a>
      <a :href="STORES.SHOP.IOS" target="_blank">
        <img width="140" src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" />
      </a>
    </center>
  </div>
</template>

<script>
import api from "@/utils/api";
import STORES from "@/utils/constants/stores";
import Bugsnag from "@bugsnag/js";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  props: {
    liveId: Number,
    default() {
      return null;
    },
  },
  data() {
    return {
      stats: {
        cart: 0,
        waiting: 0,
        orders: 0,
        shared: 0,
        viewers: 0,
        todayCount: 0,
        waitingPayment: 0,
        waitingShipping: 0,
      },
      STORES
    };
  },
  computed: {
    ...mapGetters("authentication", ["userInfo"]),
  },
  created() {
    api
      .getShopDashboard(this.liveId)
      .then(response => (this.stats = response.data))
      .catch(e => {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      });
  },
};
</script>

<style scoped></style>
