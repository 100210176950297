<template>
  <div class="mt-5">
    <div class="text-center my-2" v-if="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-container v-else>
      <h1>
        <b-button
          v-if="order.live"
          pill
          class="order-back-button"
          variant="dark"
          size="sm"
          :to="
            $route.params.liveId
              ? { name: 'MyShop/Live', params: { id: $route.params.liveId } }
              : { name: 'MyShop/Orders' }
          "
        >
          <b-icon-chevron-left></b-icon-chevron-left>
        </b-button>
        {{ order.live ? $t("order.title-user", { shopName: order.live.shop.name }) : $t("order.title") }}
      </h1>

      <b-row align-v="stretch" class="mt-5">
        <b-col cols="4">
          <b-card class="h-100">
            <h4>{{ $t("order.contact") }}</h4>
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t("order.name") }}:</strong>&nbsp;{{ order.user.name }}
              </li>
              <li>
                <strong>{{ $t("order.email") }}:</strong>&nbsp;{{ order.user.email }}
              </li>
              <li v-if="order.user.phone">
                <strong>{{ $t("order.phone") }}:</strong>&nbsp;{{ order.user.phone }}
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card class="h-100">
            <h4>{{ $t("order.order-details") }}</h4>
            <ul class="list-unstyled">
              <li v-if="order.date">
                <strong>{{ $t("order.date") }}:</strong>&nbsp;{{ formatDate(order.date) }}
              </li>
              <li v-if="order.live && order.live.expectedStartTime">
                <strong>{{ $t("order.live-date") }}:</strong>&nbsp;{{ formatDate(order.live.expectedStartTime) }}
              </li>
              <li>
                <strong>{{ $t("order.payment-method") }}:</strong>
                &nbsp;{{ $t(`stream.cart.payment-method.${order.paymentMethod}`) }}
              </li>
              <li v-if="discount">
                <strong v-if="discount.discountInfos === 'shared-10'">{{ $t("order.discount") }}:</strong>&nbsp;{{
                  formattedDiscount
                }}
              </li>
              <li>
                <strong>{{ $t("order.total") }}:</strong>&nbsp;
                <span v-if="order.totalWithDiscount && order.totalWithDiscount.amount !== order.total.amount">
                  <span class="strike mr-2">{{ formatPrice(order.total) }}</span>
                  <b>{{ formatPrice(order.totalWithDiscount) }}</b>
                </span>
                <span v-else>{{ formatPrice(order.total) }}</span>
              </li>
              <li>
                <strong>{{ $t("order.status") }}:</strong>
                <b-form-select
                  v-model="order.status"
                  :options="statusOptions"
                  @change="updateStatus"
                  size="sm"
                ></b-form-select>
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card class="h-100">
            <h4>{{ $t("order.delivery-address") }}</h4>
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t("order.delivery-method") }}:</strong>
                &nbsp;{{ $t(`stream.cart.delivery-method.${order.deliveryMethod}`) }}
                <br />
              </li>
              <div v-if="deliveryAddress">
                <li>
                  {{ deliveryAddress.address1 }}
                </li>
                <li v-if="deliveryAddress.address2">
                  {{ deliveryAddress.address2 }}
                </li>
                <li>{{ deliveryAddress.postalCode }}, {{ deliveryAddress.city }}</li>
                <li>
                  {{ deliveryAddress.country }}
                </li>
              </div>
              <li v-if="shipping">
                <br />
                <strong>{{ $t("order.shipping-cost") }}:</strong>&nbsp;{{ formatPrice(shipping.totalPrice) }}
              </li>
              <li v-if="order.comment">
                <br />
                <strong>{{ $t("order.user-comment") }}:</strong>
                <p>{{ order.comment }}</p>
                <p v-if="order.hasVoucher">{{ $t("stream.cart.has-voucher") }}</p>
                <br />
              </li>
              <li v-if="order.orderShipping">
                <br />
                <strong>{{ $t("order.tracking-number") }}:</strong> {{ order.orderShipping.trackingNumber }}
              </li>
              <li v-if="parcelShop && parcelShop.LgAdr1">
                <br />
                <strong>{{ $t("order.parcel-shop") }}:</strong> {{ parcelShop.LgAdr1 }}
              </li>
            </ul>
            <b-button @click="showOrderWeightModal()" v-if="shippingPrice && false" size="sm" class="mr-1">
              <div v-if="!order.orderShipping">
                {{ $t("orders.parcel.sending") }}<br />
                {{ formatPrice(shippingPrice) }}
              </div>
              <div v-else>
                {{ $t("orders.parcel.download_label") }}
              </div>
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-table responsive :items="products" :fields="columns" class="mt-5">
        <template #cell(images)="row">
          <b-img v-if="row.item.product.photoUrl" :src="row.item.product.photoUrl" width="50" fluid />
        </template>
      </b-table>
      <div>{{ $t("stream.cart.sub-total", { subTotal: this.formatPrice(this.totalPrice()) }) }}</div>
    </b-container>
    <b-modal
      ref="parcel-label-modal"
      @ok="getParcelLabel()"
      :title="$t('orders.parcel.parcel_weight')"
    >
      <b-form-input
        type="number"
        :placeholder="$t('orders.parcel.parcel_weight')"
        class="d-inline w-auto mr-3"
        v-model="shippingLabel.weight"
      ></b-form-input
      >kg
    </b-modal>
  </div>
</template>

<script>
import api from "@/utils/api";
import helper from "@/utils/helper";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Order",
  data() {
    return {
      order: null,
      isLoading: false,
      shippingLabel: {
        weight: 0,
      },
      shippingPrice: null,
      parcelShop: null,
      statusOptions: [
        { value: "waiting_payment_bank_wire", text: this.$t(`orders.status.waiting_payment_bank_wire`) },
        { value: "waiting_payment_shop", text: this.$t(`orders.status.waiting_payment_shop`) },
        { value: "paid", text: this.$t(`orders.status.paid`) },
        { value: "paid_with_voucher", text: this.$t("orders.status.paid_with_voucher") },
        { value: "package_in_preparation", text: this.$t(`orders.status.package_in_preparation`) },
        { value: "available_in_shop", text: this.$t("orders.status.available_in_shop") },
        { value: "shipped", text: this.$t(`orders.status.shipped`) },
        { value: "cancelled", text: this.$t(`orders.status.cancelled`) },
        { value: "closed", text: this.$t("orders.status.closed") },
      ],
      columns: [
        {
          key: "images",
          label: this.$t("order.items.columns.photo"),
          thStyle: "width: 50px;",
        },
        {
          key: "product",
          label: this.$t("order.items.columns.name"),
          sortable: true,
          class: "align-middle",
          formatter: value => {
            let name = value.name;

            if (this.order.live) {
              const product = this.order.live.products.find(liveProduct => liveProduct.product.id === value.id);
              name += ` #${product.order}`;
            }

            return name;
          },
        },
        {
          key: "productVariant",
          label: "",
          sortable: false,
          class: "align-middle",
          formatter: value => {
            return value ? value.attributes.size : "";
          },
        },
        {
          key: "unitPrice",
          label: this.$t("order.items.columns.unit-price"),
          sortable: true,
          class: "align-middle",
          formatter: value => this.formatPrice(value),
        },
        {
          key: "quantity",
          label: this.$t("order.items.columns.quantity"),
        },
        {
          key: "totalPrice",
          label: this.$t("order.items.columns.total-price"),
          sortable: true,
          class: "align-middle",
          formatter: value => this.formatPrice(value),
        },
      ],
    };
  },
  computed: {
    status() {
      return this.order ? this.order.status : null;
    },
    products() {
      return this.order.items ? this.order.items.filter(item => item.type === "product") : [];
    },
    shipping() {
      return this.order.items ? this.order.items.find(item => item.type === "shipping") : null;
    },
    discount() {
      return this.order.items ? this.order.items.find(item => item.type === "discount") : null;
    },
    formattedDiscount() {
      return this.discount.discountType === "percentage"
        ? `${this.discount.discount} %`
        : `-${this.formatPrice({ amount: this.discount.discount, currency: this.order.total.currency })}`;
    },
    deliveryAddress() {
      if (!this.order) {
        return;
      }

      if (this.order.deliveryMethod !== "shop_pick_up") {
        return this.order.deliveryAddress;
      }

      if (this.order.deliveryShop) {
        return this.order.deliveryShop;
      }

      return this.order.live?.shop.address;
    },
  },
  created() {
    this.fetchOrder()
  },
  methods: {
    fetchOrder() {
      this.isLoading = true;

      api
        .getOrder(this.$route.params.id)
        .then(response => (this.order = response.data))
        .catch(e => {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        })
        .then(() => {
          api.getOrderShippingPrice(this.$route.params.id).then(r => (this.shippingPrice = r.data.price));
          api.getOrderShippingParcelShopDetails(this.$route.params.id).then(r => (this.parcelShop = r.data));
        })
        .then(() => (this.isLoading = false));
    },
    formatDate(date) {
      return helper.formatDate(date);
    },
    formatPrice(price) {
      return helper.formatPrice(price);
    },
    totalPrice() {
      return {
        amount: this.products.reduce((previous, current) => previous + (current.totalPrice.amount ?? 0), 0),
        currency: this.products[0]?.totalPrice.currency ?? "EUR",
      };
    },
    showOrderWeightModal() {
      if (!this.order.orderShipping) {
        this.$refs["parcel-label-modal"].show();
        this.shippingLabel = {
          weight: this.order.weight,
        };
      } else {
        this.getParcelLabel(this.order.id);
      }
    },
    getOrderShippingPrice(orderId) {
      return api.getOrderShippingPrice(orderId);
    },
    getOrderShippingParcelShopDetails(orderId) {
      return api.getOrderShippingParcelShopDetails(orderId);
    },
    async getParcelLabel() {
      try {
        const { data } = await api.getParcelLabel(this.order.id, this.shippingLabel.weight);
        window.open(data.labelUrl);
        this.fetchOrder();
      } catch (e) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    updateStatus() {
      api
        .updateOrderStatus(this.order.id, this.order.status)
        .then(() =>
          this.$bvToast.toast(this.$t("order.update-status.text"), {
            title: this.$t("order.update-status.title"),
            variant: "success",
            solid: true,
          })
        )
        .catch(e => {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }

          setTimeout(() => location.reload(), 2000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-back-button {
  padding: 4px 8px 5px 7px;
  position: relative;
  top: -5px;
}
</style>
