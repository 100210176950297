<template>
  <div class="mt-5 pb-5">
    <b-container>
      <div class="float-right">
        {{ $t('seller.myshop.downloadApp') }}
        <a :href="STORES.SHOP.ANDROID" target="_blank">
          <img width="180" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
        </a>
        <a :href="STORES.SHOP.IOS" target="_blank">
          <img width="140" src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" />
        </a>
      </div>
      <h1>{{ $t("seller.configuration.title") }}</h1>
      <hr />
      <h3 class="mb-3">{{ $t("seller.configuration.shop.title") }}</h3>
      <form ref="shopForm" v-if="shop">
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('seller.configuration.shop.form.name')"
              label-for="shop-name-input"
              :invalid-feedback="$t('seller.configuration.shop.validation.name')"
              :state="fieldIsValid(shop.name)"
            >
              <b-form-input id="shop-name-input" v-model="shop.name" :state="fieldIsValid(shop.name)" required />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('seller.configuration.shop.form.vat')"
              label-for="shop-vat-input"
              :invalid-feedback="$t('seller.configuration.shop.validation.vat')"
              :state="fieldIsValid(shop.vat)"
            >
              <b-form-input id="shop-vat-input" v-model="shop.vat" :state="fieldIsValid(shop.vat)" required />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('seller.configuration.shop.form.email')"
              label-for="shop-email-input"
              :invalid-feedback="$t('seller.configuration.shop.validation.email')"
              :state="fieldIsValid(shop.email) && emailIsValid(shop.email)"
            >
              <b-form-input
                id="shop-email-input"
                v-model="shop.email"
                :state="fieldIsValid(shop.email) && emailIsValid(shop.email)"
                required
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('seller.configuration.shop.form.phone')" label-for="shop-phone-input">
              <b-form-input id="shop-phone-input" v-model="shop.phone" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-form-group :label="$t('seller.configuration.shop.form.description')" label-for="shop-description-input">
              <b-form-textarea
                id="shop-description-input"
                v-model="shop.description"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <image-input
              id="shop-logo-input"
              :label="$t('seller.configuration.shop.form.logo')"
              :image="logo"
              @imageUpdate="handleImageUpdate"
            ></image-input>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('user.address.form.address1')"
              label-for="address-address1-input"
              :invalid-feedback="$t('user.address.validation.address1')"
              :state="fieldIsValid(shop.address.address1)"
            >
              <b-form-input
                id="address-address1-input"
                v-model="shop.address.address1"
                :state="fieldIsValid(shop.address.address1)"
                required
              />
            </b-form-group>
            <b-form-group :label="$t('user.address.form.address2')" label-for="address-address2-input">
              <b-form-input id="address-address2-input" v-model="shop.address.address2" />
            </b-form-group>
            <b-form-group
              :label="$t('user.address.form.postalCode')"
              label-for="address-postalCode-input"
              :invalid-feedback="$t('user.address.validation.postalCode')"
              :state="fieldIsValid(shop.address.postalCode)"
            >
              <b-form-input
                id="address-postalCode-input"
                v-model="shop.address.postalCode"
                :state="fieldIsValid(shop.address.postalCode)"
                required
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.address.form.city')"
              label-for="address-city-input"
              :invalid-feedback="$t('user.address.validation.city')"
              :state="fieldIsValid(shop.address.city)"
            >
              <b-form-input
                id="address-city-input"
                v-model="shop.address.city"
                :state="fieldIsValid(shop.address.city)"
                required
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.address.form.country')"
              label-for="address-country-input"
              :invalid-feedback="$t('user.address.validation.country')"
              :state="fieldIsValid(shop.address.country)"
            >
              <b-form-select
                id="address-country-input"
                v-model="shop.address.country"
                :options="countries"
                :state="fieldIsValid(shop.address.country)"
                required
              ></b-form-select>
            </b-form-group>

            <hr />
            <b-form-group
              :label="$t('seller.configuration.iban')"
              label-for="configuration-iban-input"
              v-if="!configuration.bankWireDisabled"
            >
              <b-form-input id="configuration-iban-input" v-model="configuration.iban" type="text" />
            </b-form-group>
            <b-form-checkbox
              v-if="isFlixby"
              v-model="configuration.bankWireDisabled"
              :value="true"
              :unchecked-value="false"
              class="mb-3"
            >
              {{ $t("seller.configuration.disable-bank-wire") }}
            </b-form-checkbox>
          </b-col>
        </b-row>

        <template v-if="isFlixby">
          <hr class="mb-4 mt-4" />
          <b-row class="mb-5">
            <b-col>
              <h4 class="mb-3">{{ $t("seller.configuration.shop.other-shops") }}</h4>
            </b-col>
            <b-col class="text-right">
              <b-button @click="addOtherShop" variant="outline-dark">
                <b-icon-plus></b-icon-plus>
                {{ $t("seller.configuration.shop.add-other-shop") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" md="3" class="mb-5" v-for="(otherShop, index) in shop.otherShops" :key="index">
              <b-form-group
                label-size="sm"
                class="mb-1"
                label-class="mb-0"
                :label="$t('user.address.form.address1')"
                label-for="address-address1-input"
                :invalid-feedback="$t('user.address.validation.address1')"
                :state="fieldIsValid(otherShop.address1)"
              >
                <b-form-input
                  size="sm"
                  id="address-address1-input"
                  v-model="otherShop.address1"
                  :state="fieldIsValid(otherShop.address1)"
                  required
                />
              </b-form-group>
              <b-form-group
                label-size="sm"
                class="mb-0"
                label-class="mb-0"
                :label="$t('user.address.form.address2')"
                label-for="address-address2-input"
              >
                <b-form-input size="sm" id="address-address2-input" v-model="otherShop.address2" />
              </b-form-group>
              <b-form-group
                label-size="sm"
                class="mb-0"
                label-class="mb-0"
                :label="$t('user.address.form.postalCode')"
                label-for="address-postalCode-input"
                :invalid-feedback="$t('user.address.validation.postalCode')"
                :state="fieldIsValid(otherShop.postalCode)"
              >
                <b-form-input
                  size="sm"
                  id="address-postalCode-input"
                  v-model="otherShop.postalCode"
                  :state="fieldIsValid(otherShop.postalCode)"
                  required
                />
              </b-form-group>
              <b-form-group
                label-size="sm"
                class="mb-0"
                label-class="mb-0"
                :label="$t('user.address.form.city')"
                label-for="address-city-input"
                :invalid-feedback="$t('user.address.validation.city')"
                :state="fieldIsValid(otherShop.city)"
              >
                <b-form-input
                  size="sm"
                  id="address-city-input"
                  v-model="otherShop.city"
                  :state="fieldIsValid(otherShop.city)"
                  required
                />
              </b-form-group>
              <b-form-group
                label-size="sm"
                class="mb-0"
                label-class="mb-0"
                :label="$t('user.address.form.country')"
                label-for="address-country-input"
                :invalid-feedback="$t('user.address.validation.country')"
                :state="fieldIsValid(otherShop.country)"
              >
                <b-form-select
                  size="sm"
                  id="address-country-input"
                  v-model="otherShop.country"
                  :options="countries"
                  :state="fieldIsValid(otherShop.country)"
                  required
                ></b-form-select>
              </b-form-group>
              <div class="text-right">
                <b-button size="sm" class="mt-2" type="button" variant="danger" @click="removeOtherShop(index)">
                  <b-icon-trash></b-icon-trash>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </template>

        <hr class="mb-4 mt-4" />
        <b-row>
          <b-col>
            <p>{{ $t("seller.configuration.facebook-connect") }}</p>
          </b-col>
          <b-col class="text-right">
            <b-col v-if="!showFacebookConnect">
              <p>
                {{ $t("seller.configuration.facebook-connected-page") }}: {{ configuration.facebookPage.name }}
                <b-icon-x-circle-fill
                  id="revoke-fb-btn"
                  class="ml-2"
                  @click="revokeFacebook"
                  :title="$t('seller.configuration.facebook-revoke')"
                ></b-icon-x-circle-fill>
              </p>
            </b-col>
            <v-facebook-login
              :app-id="fbId"
              :login-options="{ scope: 'pages_show_list,pages_read_engagement,pages_manage_posts' }"
              v-model="facebook"
              @sdk-init="onSDKInit"
              @login="onFacebookConnect"
              v-if="showFacebookConnect"
            ></v-facebook-login>
          </b-col>
        </b-row>

        <template v-if="isFlixby">
          <hr class="mb-4 mt-4" />
          <b-row>
            <b-col>
              <p>{{ $t("seller.configuration.stripe-connect") }}</p>
            </b-col>
            <b-col class="text-right">
              <b-button @click="stripeConnect" :disabled="stripeConnectLoading" v-if="!stripeConnectCompleted">
                {{ $t("seller.configuration.stripe-connect-btn") }}
              </b-button>
              <b-button variant="success" disabled v-else>
                <b-icon-check-all></b-icon-check-all>
                {{ $t("seller.configuration.stripe-connect-completed") }}
              </b-button>
            </b-col>
          </b-row>

          <template v-if="showShopifyConnect && showDevConfiguration">
            <hr class="mb-4 mt-4" />
            <b-row>
              <b-col>
                <p>{{ $t("seller.configuration.shopify-connect") }}</p>
              </b-col>
              <b-col class="text-right">
                <b-col>
                  <p>
                    {{ $t("seller.configuration.shopify-connected") }}
                    <b-icon-x-circle-fill
                      id="revoke-shopify-btn"
                      class="ml-2"
                      @click="revokeShopify"
                      :title="$t('seller.configuration.shopify-revoke')"
                    ></b-icon-x-circle-fill>
                  </p>
                </b-col>
              </b-col>
            </b-row>
          </template>

          <hr class="mb-4 mt-4" />
          <b-row>
            <b-col>
              <p>{{ $t("seller.configuration.prestashop.title") }}</p>
            </b-col>
            <b-col class="text-right">
              <b-col>
                <p v-if="configuration.prestashop && configuration.prestashop.accessToken">
                  {{ $t("seller.configuration.prestashop.connected") }}
                  <b-icon-x-circle-fill
                    id="revoke-prestashop-btn"
                    class="ml-2"
                    @click="revokePrestashop"
                    :title="$t('seller.configuration.prestashop.revoke')"
                  ></b-icon-x-circle-fill>
                </p>
                <b-button variant="dark" @click="downloadPrestashopZip">
                  <b-spinner small type="grow" v-if="loadingPrestashopZip" class="align-middle mr-2"></b-spinner>
                  <b-icon-download v-else class="align-middle mr-2"></b-icon-download>
                  {{ $t("seller.configuration.prestashop.download") }}
                </b-button>
              </b-col>
            </b-col>
          </b-row>

          <hr class="mb-4 mt-4" />
          <b-row>
            <b-col sm="6">
              <h3 class="mb-3">{{ $t("seller.configuration.payment-methods.title") }}</h3>
              <b-form-group :label="$t('seller.configuration.payment-methods.label')">
                <b-form-checkbox-group
                  id="enabledPaymentMethods"
                  v-model="configuration.enabledPaymentMethods"
                  :options="availablePaymentMethods"
                  name="enabledPaymentMethods"
                  stacked
                >
                  <b-form-invalid-feedback :state="configuration.enabledPaymentMethods.length >= 1">
                    {{ $t("seller.configuration.payment-methods.error") }}
                  </b-form-invalid-feedback>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <h3 class="mb-3">{{ $t("seller.configuration.delivery-methods.title") }}</h3>
              <b-form-group :label="$t('seller.configuration.delivery-methods.label')">
                <b-form-checkbox-group
                  id="enabledDeliveryMethods"
                  v-model="configuration.enabledDeliveryMethods"
                  :options="availableDeliveryMethods"
                  name="enabledDeliveryMethods"
                  stacked
                >
                  <b-form-invalid-feedback :state="configuration.enabledDeliveryMethods.length >= 1">
                    {{ $t("seller.configuration.delivery-methods.error") }}
                  </b-form-invalid-feedback>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>

          <template v-if="enabledDeliveryMethods.length > 0">
            <hr class="mb-4 mt-4" />
            <h3 class="mb-3">{{ $t("seller.configuration.delivery-methods.configuration-title") }}</h3>
            <b-row>
              <b-col sm="12">
                <ul class="list-unstyled">
                  <li v-for="deliveryMethod in enabledDeliveryMethods" :key="deliveryMethod" class="mb-3">
                    <h6>{{ $t(`seller.configuration.delivery-methods.${deliveryMethod}`) }}</h6>
                    <ul class="list-unstyled" v-if="configuration.shipping !== null">
                      <li
                        v-for="(deliveryMethodConfig, index) in configuration.shipping[deliveryMethod]"
                        :key="`delivery-method-${deliveryMethod}-config-${index}`"
                      >
                        <b-row align-v="end" class="uk-margin-small-bottom">
                          <b-col md="3">
                            <b-form-group
                              label-size="sm"
                              class="uk-margin-remove-top uk-margin-remove-bottom p-0"
                              label-class="uk-margin-remove-top uk-margin-remove-bottom p-0"
                              :label="$t('seller.configuration.delivery-methods.country')"
                              :label-for="`delivery-method-${deliveryMethod}-config-${index}-country`"
                            >
                              <v-select
                                :id="`delivery-method-${deliveryMethod}-config-${index}-country`"
                                :options="shippingCountries()"
                                label="text"
                                :reduce="country => country.value"
                                v-model="deliveryMethodConfig.countries"
                                multiple
                              ></v-select>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label-size="sm"
                              class="uk-margin-remove-top uk-margin-remove-bottom p-0"
                              label-class="uk-margin-remove-top uk-margin-remove-bottom p-0"
                              :label="$t('seller.configuration.delivery-methods.price')"
                              :label-for="`delivery-method-${deliveryMethod}-config-${index}-price-input`"
                            >
                              <b-input-group append="€">
                                <b-form-input
                                  :id="`delivery-method-${deliveryMethod}-config-${index}-price-input`"
                                  :value="priceInputValue(deliveryMethodConfig.price)"
                                  @input="newPrice => (deliveryMethodConfig.price = formatPriceForDatabase(newPrice))"
                                  required
                                  min="0"
                                  step=".01"
                                  type="number"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label-size="sm"
                              class="uk-margin-remove-top uk-margin-remove-bottom p-0"
                              label-class="uk-margin-remove-top uk-margin-remove-bottom p-0"
                              :label="$t('seller.configuration.delivery-methods.free-amount')"
                              :label-for="`delivery-method-${deliveryMethod}-config-${index}-free-amount-input`"
                            >
                              <b-input-group append="€">
                                <b-form-input
                                  :id="`delivery-method-${deliveryMethod}-config-${index}-free-amount-input`"
                                  :value="
                                    deliveryMethodConfig.freeAmount !== null
                                      ? deliveryMethodConfig.freeAmount / 100
                                      : null
                                  "
                                  @input="
                                    newPrice => (deliveryMethodConfig.freeAmount = formatPriceForDatabase(newPrice))
                                  "
                                  min="0"
                                  step=".01"
                                  type="number"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="auto">
                            <b-button
                              variant="danger"
                              @click="() => configuration.shipping[deliveryMethod].splice(index, 1)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                          </b-col>
                        </b-row>
                      </li>
                      <li>
                        <b-row align-v="end" class="uk-margin-small-bottom">
                          <b-col md="9">
                            <b-button
                              class="uk-width-1-1"
                              variant="outline-dark"
                              @click="() => addDeliveryConfiguration(configuration.shipping[deliveryMethod])"
                            >
                              <b-icon-plus class="mr-1"></b-icon-plus> {{ $t("common.add") }}
                            </b-button>
                          </b-col>
                        </b-row>
                      </li>
                    </ul>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </template>
        </template>

        <hr class="mb-4 mt-4" />
        <h3 class="mb-3">{{ $t("seller.configuration.lives.title") }}</h3>
        <template v-if="isFlixby">
          <b-row>
            <b-col sm="6">
              <b-form-checkbox
                v-model="configuration.shippingCostDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-shipping-cost") }}
              </b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="$t('seller.configuration.share-discount')"
                label-for="configuration-share-discount-input"
                v-if="!configuration.shareDiscountDisabled"
              >
                <b-input-group append="%">
                  <b-form-input
                    id="configuration-share-discount-input"
                    v-model="configuration.shareDiscount"
                    type="number"
                    number
                    min="0"
                    step="1"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-checkbox
                v-model="configuration.shareDiscountDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-share-discount") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                :label="$t('seller.configuration.replay-limit')"
                label-for="configuration-replay-limit-input"
                v-if="!configuration.replayLimitDisabled"
              >
                <b-form-input
                  id="configuration-replay-limit-input"
                  v-model="configuration.replayLimit"
                  type="number"
                  number
                  min="0"
                  step="1"
                />
              </b-form-group>
              <b-form-checkbox
                v-model="configuration.replayLimitDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-replay-limit") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-checkbox
                v-model="configuration.shopPickUpDisabled"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                {{ $t("seller.configuration.disable-shop-pick-up") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col sm="6">
            <b-form-checkbox
              v-model="configuration.showProductStockDisabled"
              :value="true"
              :unchecked-value="false"
              class="mb-3"
            >
              {{ $t("seller.configuration.disable-show-product-stock") }}
            </b-form-checkbox>
          </b-col>
          <b-col sm="6">
            <b-form-checkbox
              v-model="configuration.showProductBeforeStartDisabled"
              :value="true"
              :unchecked-value="false"
              class="mb-3"
            >
              {{ $t("seller.configuration.disable-show-product-before-start") }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="configuration.showProductBeforeSelectedDisabled"
              :value="true"
              :unchecked-value="false"
              class="mb-3"
            >
              {{ $t("seller.configuration.disable-show-product-before-selected") }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <div>
          <hr class="mb-4 mt-4" />
          <h3 class="mb-3">{{ $t("seller.configuration.accent-color") }}</h3>
          <b-row>
            <b-col sm="6">
              <chrome-picker :value="configuration.color" @input="updateColor" />
            </b-col>
          </b-row>
        </div>
      </form>
      <div class="text-center my-2" v-else>
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div v-if="qrCode && isSellerQr">
        <hr class="mb-4 mt-4" />
        <h3 class="mb-3">{{ $t("seller.configuration.application.title") }}</h3>
        <b-row>
          <b-col sm="6">
            <span v-html="qrCode"></span>
          </b-col>
        </b-row>
      </div>
      <div class="text-right mb-5">
        <b-button @click="save">{{ $t("common.save-btn") }}</b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import VFacebookLogin from "vue-facebook-login-component";
import api from "@/utils/api";
import ImageInput from "@/components/ImageInput";
import STORES from "@/utils/constants/stores";
import Bugsnag from "@bugsnag/js";
import { mapGetters } from "vuex";
import { Chrome as ChromePicker } from "vue-color";
import vSelect from "vue-select";
import Vue from "vue";
import helper from "@/utils/helper";

export default {
  name: "Configuration",
  components: {
    VFacebookLogin,
    ImageInput,
    ChromePicker,
    vSelect,
  },
  data() {
    return {
      configuration: {
        shippingCost: null,
        shippingCostDisabled: false,
        iban: null,
        bankWireDisabled: false,
        shareDiscount: 10,
        shareDiscountDisabled: false,
        replayLimit: null,
        replayLimitDisabled: true,
        showProductBeforeStartDisabled: false,
        showProductBeforeSelectedDisabled: false,
        shopPickUpDisabled: false,
        color: "#F27B21",
        shopifyAccessToken: false,
        shopifyShopName: null,
        enabledPaymentMethods: [],
        enabledDeliveryMethods: [],
        shipping: null,
      },
      STORES,
      shop: null,
      fbId: process.env.VUE_APP_FACEBOOK_PRO_ID,
      facebook: null,
      FB: null,
      countries: [
        { value: "AT", text: this.$t("user.address.form.countries.AT") },
        { value: "BE", text: this.$t("user.address.form.countries.BE") },
        { value: "BG", text: this.$t("user.address.form.countries.BG") },
        { value: "HR", text: this.$t("user.address.form.countries.HR") },
        { value: "CH", text: this.$t("user.address.form.countries.CH") },
        { value: "CY", text: this.$t("user.address.form.countries.CY") },
        { value: "CZ", text: this.$t("user.address.form.countries.CZ") },
        { value: "DK", text: this.$t("user.address.form.countries.DK") },
        { value: "EE", text: this.$t("user.address.form.countries.EE") },
        { value: "FI", text: this.$t("user.address.form.countries.FI") },
        { value: "FR", text: this.$t("user.address.form.countries.FR") },
        { value: "DE", text: this.$t("user.address.form.countries.DE") },
        { value: "GR", text: this.$t("user.address.form.countries.GR") },
        { value: "HU", text: this.$t("user.address.form.countries.HU") },
        { value: "IE", text: this.$t("user.address.form.countries.IE") },
        { value: "IT", text: this.$t("user.address.form.countries.IT") },
        { value: "LV", text: this.$t("user.address.form.countries.LV") },
        { value: "LT", text: this.$t("user.address.form.countries.LT") },
        { value: "LU", text: this.$t("user.address.form.countries.LU") },
        { value: "MT", text: this.$t("user.address.form.countries.MT") },
        { value: "NL", text: this.$t("user.address.form.countries.NL") },
        { value: "PL", text: this.$t("user.address.form.countries.PL") },
        { value: "PT", text: this.$t("user.address.form.countries.PT") },
        { value: "RO", text: this.$t("user.address.form.countries.RO") },
        { value: "SK", text: this.$t("user.address.form.countries.SK") },
        { value: "SI", text: this.$t("user.address.form.countries.SI") },
        { value: "ES", text: this.$t("user.address.form.countries.ES") },
        { value: "SE", text: this.$t("user.address.form.countries.SE") },
        { value: "US", text: this.$t("user.address.form.countries.US") },
      ],
      availablePaymentMethods: [],
      availableDeliveryMethods: [],
      qrCode: null,
      stripeConnectCompleted: false,
      stripeConnectLoading: false,
      loadingPrestashopZip: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["isSellerQr", "userInfo"]),
    showFacebookConnect() {
      return !this.configuration.facebookPage;
    },
    showShopifyConnect() {
      return this.configuration.shopifyAccessToken;
    },
    showDevConfiguration() {
      return process.env.NODE_ENV !== "production";
    },
    apiUrl() {
      return process.env.VUE_APP_BACKEND_URL;
    },
    logo() {
      if (this.shop.logoDataUri) {
        return this.shop.logoDataUri;
      }

      if (this.shop.logoUrl) {
        return `${this.apiUrl}/${this.shop.logoUrl}`;
      }

      return null;
    },
    enabledDeliveryMethods() {
      return [...this.configuration.enabledDeliveryMethods].sort();
    },
    isFlixby() {
      return this.userInfo.shop.type === "flixby";
    },
  },
  created() {
    api
      .getShop()
      .then(response => (this.shop = response.data))
      .then(() => this.refreshConfiguration())
      .then(() => Promise.all([this.refreshPaymentMethodsFees(), this.refreshDeliveryMethods()]))
      .then(() => {
        this.$watch("configuration.enabledDeliveryMethods", this.enabledDeliveryMethodsChanged);
      })
      .catch(e => this.handleError(e));
    this.checkStripeConnectStatus();

    if (this.isSellerQr) {
      api
        .getAppQrCode()
        .then(qrCode => (this.qrCode = qrCode))
        .catch(e => console.log(e));
    }
  },
  methods: {
    enabledDeliveryMethodsChanged(newValue, oldValue) {
      const addedDeliveryMethods = newValue.filter(x => !oldValue.includes(x));
      const removedDeliveryMethods = oldValue.filter(x => !newValue.includes(x));
      console.log(addedDeliveryMethods);
      console.log(removedDeliveryMethods);

      if (addedDeliveryMethods.length > 0 && !this.configuration.shipping) {
        this.configuration.shipping = {};
      }

      addedDeliveryMethods.forEach(addedDeliveryMethod => {
        if (this.configuration.shipping[addedDeliveryMethod] !== undefined) {
          return;
        }

        const newDeliveryMethodConfig = [
          {
            countries: [this.shop.address.country],
            price: 0,
            freeAmount: null,
          },
          {
            countries: ["default"],
            price: 0,
            freeAmount: null,
          },
        ];

        Vue.set(this.configuration.shipping, addedDeliveryMethod, newDeliveryMethodConfig);
      });
    },
    addDeliveryConfiguration(shippingMethod) {
      shippingMethod.push({
        countries: [],
        price: 0,
        freeAmount: null,
      });
    },
    shippingCountries() {
      return [
        { value: "EU", text: this.$t("seller.configuration.delivery-methods.europe") },
        ...this.countries,
        { value: "default", text: this.$t("seller.configuration.delivery-methods.other-countries") },
      ];
    },
    handleImageUpdate(base64Image) {
      this.shop.logoDataUri = base64Image;
    },
    refreshConfiguration() {
      return api
        .getShopConfiguration()
        .then(response => (this.configuration = { ...this.configuration, ...response.data }))
        .catch(e => this.handleError(e));
    },
    refreshPaymentMethodsFees() {
      return api
        .getShopPaymentMethodsFees()
        .then(response => {
          this.availablePaymentMethods = [];
          for (const [paymentMethod, fee] of Object.entries(response.data)) {
            this.availablePaymentMethods.push({
              value: paymentMethod,
              text:
                this.$t(`seller.configuration.payment-methods.${paymentMethod}`) +
                (fee > 0 ? ` - ${this.$t(`seller.configuration.payment-methods.fee`, { fee })}` : ""),
              disabled: paymentMethod === "bank_wire" && this.configuration.bankWireDisabled,
            });
          }
        })
        .catch(e => this.handleError(e));
    },
    refreshDeliveryMethods() {
      return api
        .getShopDeliveryMethods()
        .then(response => {
          this.availableDeliveryMethods = response.data.map(deliveryMethod => ({
            value: deliveryMethod,
            text: this.$t(`seller.configuration.delivery-methods.${deliveryMethod}`),
          }));
        })
        .catch(e => this.handleError(e));
    },
    priceInputValue(price) {
      return price ? price / 100 : 0;
    },
    setPrice(configurationKey, newPrice) {
      this.configuration[configurationKey] = this.formatPriceForDatabase(newPrice);
    },
    formatPriceForDatabase(price) {
      return price || price === 0 ? parseInt((price * 100).toFixed(0)) : null;
    },
    fieldIsValid(value) {
      return value !== null && value !== undefined ? value.length > 0 : null;
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    checkFormValidity() {
      const valid = this.$refs.shopForm.checkValidity();

      if (!valid) {
        this.shop.name = this.shop.name || "";
        this.shop.email = this.shop.email || "";
        this.shop.vat = this.shop.vat || "";
      }

      return valid;
    },
    async save() {
      if (!this.checkFormValidity()) {
        return;
      }

      try {
        if (this.configuration.enabledDeliveryMethods.length === 0) {
          this.configuration.shipping = null;
        }

        await Promise.all([api.updateShopConfiguration(this.configuration), api.updateShop(this.shop)]);

        this.$bvToast.toast(this.$t("seller.configuration.saved.text"), {
          title: this.$t("seller.configuration.saved.title"),
          variant: "success",
          solid: true,
        });
      } catch (e) {
        this.handleError(e);
      }
    },
    handleError(e) {
      this.$bvToast.toast(this.$t("common.error.text"), {
        title: this.$t("common.error.title"),
        variant: "danger",
        solid: true,
      });

      console.error(e);
      if (process.env.NODE_ENV === "production") {
        Bugsnag.leaveBreadcrumb(JSON.stringify(e));
        Bugsnag.notify(e);
      }
    },
    onSDKInit({ FB }) {
      this.FB = FB;

      //force initialization with Flixby Pro to avoid conflict with the login page button
      this.FB.init({
        appId: this.fbId,
        version: "v9.0",
        cookie: true,
        xfbml: true,
        autoLogAppEvents: true,
      });
    },
    onFacebookConnect(response) {
      if (!response || !response.authResponse) {
        return;
      }

      return api
        .sellerFacebookConnect(response.authResponse.accessToken)
        .then(() => this.refreshConfiguration())
        .catch(e => this.handleError(e));
    },
    revokeFacebook() {
      this.configuration.facebookPage = null;
    },
    revokeShopify() {
      this.configuration.shopifyShopName = null;
      this.configuration.shopifyAccessToken = false;

      //@todo: remove product-update webhook
    },
    revokePrestashop() {
      this.configuration.prestashop = null;
    },
    updateColor(color) {
      this.configuration.color = color.hex;
    },
    addOtherShop() {
      this.shop.otherShops.push({});
    },
    removeOtherShop(index) {
      this.$bvModal
        .msgBoxConfirm(this.$t("seller.configuration.shop.other-shop-delete-confirm"))
        .then(confirmed => {
          if (confirmed) {
            this.shop.otherShops.splice(index, 1);
          }
        })
        .catch(error => console.log(error));
    },
    checkStripeConnectStatus() {
      api
        .stripeConnectStatus()
        .then(response => {
          if (!response.data || response.data.completed === undefined) {
            throw new Error();
          }

          this.stripeConnectCompleted = response.data.completed;

          if (this.$route.query.action === "reauth" && !this.stripeConnectCompleted) {
            this.stripeConnect();
          }
        })
        .catch(e => this.handleError(e));
    },
    stripeConnect() {
      this.stripeConnectLoading = true;
      api
        .stripeConnect()
        .then(response => {
          if (!response.data || response.data.redirect === undefined) {
            throw new Error();
          }

          document.location = response.data.redirect;
        })
        .catch(e => {
          this.stripeConnectLoading = false;
          this.handleError(e);
        });
    },
    async downloadPrestashopZip() {
      if (this.loadingPrestashopZip) {
        return;
      }

      this.loadingPrestashopZip = true;

      try {
        const response = await api.downloadPrestashopZip();
        console.log(response.data);
        helper.download(response.data, "flixby.zip", { type: "application/zip" });
      } catch (error) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(error);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(error));
          Bugsnag.notify(error);
        }
      }

      this.loadingPrestashopZip = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-facebook-login {
  display: initial;
  cursor: pointer;
}

#revoke-fb-btn,
#revoke-shopify-btn,
#revoke-prestashop-btn {
  cursor: pointer;
}
</style>
