<template>
  <b-form-group :label="label" :label-for="id">
    <b-img :src="preview" fluid-grow></b-img>
    <b-spinner v-if="loading" small type="grow" class="align-middle"></b-spinner>
    <b-form-file :id="id" @input="handleImage" accept="image/jpeg, image/png" />
  </b-form-group>
</template>

<script>
import Compressor from "compressorjs";
import Bugsnag from "@bugsnag/js";

export default {
  name: "ImageInput",
  props: {
    id: {
      type: String,
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    image: {
      type: String,
    },
  },
  data() {
    return {
      preview: null,
      loading: false,
    };
  },
  created() {
    this.preview = this.image;
  },
  methods: {
    handleImage(file) {
      if (!file) {
        return;
      }

      this.loading = true;
      new Compressor(file, {
        quality: 0.6,
        checkOrientation: true,
        maxWidth: 1024,
        success: result => {
          this.blobToDataURI(result)
            .then(base64Result => {
              this.$emit("imageUpdate", base64Result);
              this.preview = base64Result;
              this.loading = false;
            })
            .catch(error => this.logoUploadError(error));
        },
        error: error => this.logoUploadError(error),
      });
    },
    blobToDataURI(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    logoUploadError(error) {
      this.$bvToast.toast(this.$t("common.error.text"), {
        title: this.$t("common.error.title"),
        variant: "danger",
        solid: true,
      });

      this.loading = false;

      console.log(error);
      if (process.env.NODE_ENV === "production") {
        Bugsnag.leaveBreadcrumb(JSON.stringify(error));
        Bugsnag.notify(error);
      }
    },
  },
};
</script>

<style scoped></style>
